import styled from '@emotion/styled'
import { JackpotCountupContainer } from 'assets/images';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column-reverse;
  position: absolute;
  width: 50%;
  max-width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  font-size: 30pt;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .rays {
    animation: spin 4s linear infinite;
    z-index: 3;
    width: 150%;
    height: 150%;
    top: -25%;
    left: -25%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    &.trophy {
      animation: noticeMe 0.4s linear infinite;
      z-index: 3;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }

  @keyframes noticeMe {
    0% {
      width: 100%;
      height: 100%;
    }

    50% {
      width: 80%;
      height: 80%;
    }

    100% {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Prize = styled.div`
  @property --num {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
  }

  @keyframes counter {
    from {
      --num: 0;
    }

    to {
      --num: calc(${prop => prop.prize} + 1.5);
    }
  }

  @keyframes delay-visibility {
    0% {
      opacity: 0;
    }

    99% {
      opacity: 0;
    }
  }

  color: #ffffff;
  z-index: 5;
  font-family: "Varsity Team Bold";
  font-size: 1em;
  text-align: center;
`;

export const PrizeWrapper = styled.div`
  color: #ffffff;
  z-index: 5;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  /* background-image: url(${JackpotCountupContainer}); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: unset;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 3vh;

  &::after {
    content: "";
    display: block;
    padding-bottom: 20%;
  }
`;

export const WinBarTextWrapper = styled.div`
    position: absolute;
    top: -3px;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Roboto Black';
    text-transform: uppercase;
    -webkit-text-stroke: 1px rgba(198,153,43,0.7);
    text-shadow: 2.5px 1.5px 0px rgba(242,193,96,1);
`

export const AnimatedPrizeWrapper = styled.div`
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 3;
  margin-top: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vh;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  & > span {
    position: relative;
    z-index: 9;
    top: 8px;
  }

  .prize {
    font-size: 1.2em;
    margin-top: 15px;
  }

  &::after {
    content: "";
    display: block;
    padding-bottom: 30%;
  }
`;